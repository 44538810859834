import React, { useState } from 'react';
import { css } from '@emotion/react';
import TextIconCard from './text-icon-card';
import BlockContent from './block-content';

export default function TextIconCards({ className, text, items, invertForeground }) {
  const black = invertForeground ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        text-align: center;
        color: ${black};
      `}
    >
      <div
        className="type--heading-three"
        css={css`
          width: 100%;
          max-width: 102rem;
          padding: 0 var(--gutterHalf);
          margin: 0 auto var(--margin);

          @media (max-width: 550px) {
            margin-bottom: var(--margin6);
          }
        `}
      >
        <BlockContent content={text} />
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          @media (max-width: 800px) {
            margin: 0;
          }
        `}
      >
        {items?.map((item) => {
          return (
            <div
              key={item.title}
              css={css`
                flex-basis: 33.33%;
                padding: var(--gutterHalf);
                max-width: 43.5rem;
                min-width: 35rem;

                @media (max-width: 1280px) {
                  flex-basis: 50%;
                }

                @media (max-width: 550px) {
                  flex-basis: 100%;
                  max-width: 36.5rem;
                  min-width: auto;
                }
              `}
            >
              <TextIconCard
                title={item.title}
                image={item.image}
                text={item.text}
                url={item.url}
                invertForeground={invertForeground}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
