import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Box from '../components/box';
import Video from '../components/video';
import Salon from '../components/salon';
import HorizontalRule from '../components/horizontal-rule';
import InlineGallery from '../components/inline-gallery';
import TextIconCards from '../components/text-icon-cards';
import LinkPanel from '../components/link-panel';
import Grid from '../components/grid';

export default function Studio({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(true);
    theme.setBackURL('/us');
  }, []);

  return (
    <div
      css={css`
        color: var(--white);
        background-color: var(--black);

        a,
        a:visited {
          color: var(--white);
        }
      `}
    >
      <SEO
        title="Studio"
        description={
          data.studio._rawIntroText
            ? data.studio._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
        imageID={data.studio.introMedia.type === 0 ? data.studio.introMedia.image.asset._id : null}
      />
      <div>
        <Box>
          {data.studio.introMedia.type === 0 && (
            <div
              css={css`
                img {
                  display: block;
                  width: 100%;
                  height: 100vh;
                  object-fit: cover;

                  @media (max-width: 800px) {
                    height: 105.4vw;
                    max-height: 100vh;
                  }

                  @media (max-width: 550px) {
                    height: 110vw;
                  }
                }
              `}
            >
              <SanityImage width={1980} {...data.studio.introMedia.image} />
            </div>
          )}
          {data.studio.introMedia.type === 1 && (
            <Video
              src={data.studio.introMedia.video.asset.url}
              autoPlay={true}
              muted={true}
              loop={true}
              css={css`
                width: 100%;
                height: 100vh;
                overflow: hidden;

                @media (max-width: 800px) {
                  height: 105.4vw;
                  max-height: 100vh;
                }

                @media (max-width: 550px) {
                  height: 110vw;
                  max-height: 100vh;
                }

                video {
                  height: 100vh;
                  object-fit: cover;

                  @media (max-width: 800px) {
                    height: 105.4vw;
                    max-height: 100vh;
                  }

                  @media (max-width: 550px) {
                    height: 110vw;
                  }
                }
              `}
            />
          )}
        </Box>
        <Box x={10} y={13}>
          <BlockContent className="type--heading-three" content={data.studio._rawIntroText} />
        </Box>
        <Box x={10} y={13}></Box>
        {data.studio.gallery?.length && (
          <Box x={10} y={8}>
            <Salon
              invertForeground={true}
              background="var(--black)"
              items={data.studio.gallery.map((item) => {
                if (item._type === 'captionImage') {
                  return {
                    assetType: 'image',
                    image: item.image,
                    captionTitle: item.captionTitle,
                    captionText: item._rawCaptionText,
                  };
                } else if (item._type === 'captionVideo') {
                  return {
                    assetType: 'video',
                    src: item.video.asset.url,
                    captionTitle: item.captionTitle,
                    captionText: item._rawCaptionText,
                  };
                }
              })}
            />
          </Box>
        )}
        <Box x={10} y={10}>
          <HorizontalRule invert={true} />
        </Box>
        <Box x={10} y={10}>
          <div
            className="type--heading-three"
            css={css`
              width: 100%;
              color: var(--white);
            `}
          >
            <BlockContent content={data.studio._rawLocationText} />
          </div>
        </Box>
        <Box
          y={12}
        >
          <InlineGallery 
            items={data.studio.locations.map((item) => {
              return {
                assetType: 'image',
                image: item.image,
              };
            })}
            css={css`
              color: var(--white);
            `}
          />
        </Box>
        {(data.studio._rawNominatedText) &&
          <>
            <HorizontalRule invert={true} />
            <Box x={10} y={13}>
              <Grid
                columns={10}
              >
                <div
                  className="type--paragraph"
                  css={css`
                    width: 100%;
                    color: var(--white);
                  `}
                >
                  <BlockContent content={data.studio._rawNominatedText} />
                </div>
              </Grid>
            </Box>
          </>
        }
        {(data.studio._rawHowText || data.studio.howItems.length > 0) && 
          <>
            <Box x={10} y={10}>
              <HorizontalRule invert={true} />
            </Box>
            <Box
              y={10}
              css={css`
                padding-right: calc(var(--margin) - var(--gutterHalf));
                padding-left: calc(var(--margin) - var(--gutterHalf));

                @media (max-width: 1024px) {
                  padding-right: calc(var(--gutter) - var(--gutterHalf));
                  padding-left: calc(var(--gutter) - var(--gutterHalf));
                }

                @media (max-width: 550px) {
                  padding: 0;
                }
              `}
            >
              <TextIconCards
                css={css`
                  padding-bottom: var(--margin);

                  @media (max-width: 800px) {
                    padding-bottom: var(--margin4);
                  }
                `}
                text={data.studio._rawHowText}
                invertForeground={true}
                items={data.studio.howItems.map((item) => {
                  return {
                    title: item.title,
                    image: item.image,
                    text: item.text,
                    url: item.url,
                  };
                })}
              />
            </Box>
          </>
        }
      </div>
      <LinkPanel
        hoverColor="var(--black)"
        hoverBackground="var(--white)"
        invert={true}
        content={[
          {
            text: 'Work',
            url: '/work',
          },
          {
            text: 'People',
            url: '/people',
          },
        ]}
      />
    </div>
  );
}

export const query = graphql`
  query {
    studio: sanityStudio(_id: { regex: "/(drafts\\\\.studio)?/" }) {
      introMedia {
        type
        image {
          ...Image
        }
        video {
          asset {
            url
          }
        }
      }
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      gallery {
        ... on SanityCaptionImage {
          _type
          image {
            ...Image
            asset {
              metadata {
                dimensions {
                  aspectRatio
                }
              }
            }
          }
          captionTitle
          _rawCaptionText(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityCaptionVideo {
          _type
          video {
            asset {
              url
            }
          }
          captionTitle
          _rawCaptionText(resolveReferences: { maxDepth: 10 })
        }
      }
      _rawLocationText(resolveReferences: { maxDepth: 10 })
      locations {
        image {
          ...Image
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
        text
      }
      _rawNominatedText(resolveReferences: { maxDepth: 10 })
      _rawHowText(resolveReferences: { maxDepth: 10 })
      howItems {
        image {
          ...Image
        }
        text
        title
        url
      }
    }
  }
`;
