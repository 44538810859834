import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import ConditionalWrapper from './conditional-wrapper';
import Link from './link';

export default function TextIconCard({ className, title, image, url, text, invertForeground }) {
  const white = invertForeground ? 'var(--black)' : 'var(--white)';
  const black = invertForeground ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 3.3rem 2.6rem 3.8rem;
        border: 4px solid ${black};

        @media (max-width: 800px) {
          padding: 3.3rem var(--gutterHalf) 3.8rem;
        }
      `}
    >
      <ConditionalWrapper
        condition={url}
        wrapper={(children) => (
          <Link
            to={url}
            css={css`
              color: ${black};

              &:visited {
                color: ${black};
              }
            `}
          >
            {children}
          </Link>
        )}
      >
        <h3
          className="type--heading-four"
          css={css`
            margin-bottom: 2.8rem;

            @media (max-width: 800px) {
              margin-bottom: 3.8rem;
            }
          `}
        >
          {title}
        </h3>
      </ConditionalWrapper>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10rem;
          height: 10rem;
          margin: 0 auto var(--margin5);

          @media (max-width: 550px) {
            width: 8rem;
            height: 8rem;
          }
        `}
      >
        {image && (
          <SanityImage
            {...image}
            width={660}
            style={{
              display: 'block',
              flexBasis: '100%',
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </div>
      <div
        className="type--paragraph"
        css={css`
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          text-align: left;
          overflow: hidden;
        `}
      >
        {text}
      </div>
    </div>
  );
}
